<template>
    <div class="col-4">
        <ValidationObserver ref="validacion">
            <p class="text-center f-600 f-16 mb-3">
                Ingresa los siguientes datos para <br /> solicitar tu afiliación.
            </p>
            <div class="row mx-0 j-center">
                <div v-if="convenio.cedula_requerida != 0" class="col-12 mb-3">
                    <p class="f-12 f-400 pl-2">Documento de identidad</p>
                    <ValidationProvider v-slot="{errors}" :rules="{required:validador.cedula}" name="Documento de identidad">
                        <el-input v-model="model.cedula" placeholder="Documento de identidad" class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div v-if="convenio.salario_requerido != 0" class="col-12 mb-3">
                    <p class="f-12 f-400 pl-2">Salario*</p>
                    <ValidationProvider v-slot="{errors}" :rules="{required:validador.salario}" name="Salario">
                        <el-input v-model="model.salario" placeholder="Salario" class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <p class="f-12 f-400 pl-2">Fecha de ingreso a la empresa</p>
                    <ValidationProvider v-slot="{errors}" rules="required" name="Fecha de ingreso a la empresa">
                        <el-date-picker
                        v-model="model.fechaIngreso"
                        type="date"
                        class="w-100"
                        placeholder="Seleccionar"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
            <div v-if="convenio.documento_requerido != 0" class="row mx-0 mt-2">
                <div v-if="!model.file" class="col-12">
                    <el-upload
                    ref="upload"
                    action="#"
                    :on-change="changeFile"
                    :on-remove="removeFile"
                    :multiple="false"
                    :limit="1"
                    :show-file-list="false"
                    class="upload-convenio w-100"
                    >
                        <div class="p-1 bg-white br-11 w-100">
                            <button type="button" class="button-upload-files bg-white p-1 w-100">
                                Subir {{ convenio.documento_label }}
                                </br>
                                {{ tipoDocumentoString() }}
                            </button>
                        </div>
                    </el-upload>
                </div>
                <div v-else class="col-12">
                    <div class="card-file">
                        <div class="space-file">
                            <i class="icon-image f-18" />
                        </div>
                        <div class="col d-middle f-500 f-14">
                            {{ convenio.documento_label }}
                        </div>
                        <div class="space-delete cr-pointer" @click="removeFile">
                            <el-tooltip content="Eliminar" placement="bottom" effect="light">
                                <i class="icon-trash-can-outline f-18" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <p class="text-right f-12 pr-4 mt-2">
                *Datos obligatorios
            </p>
            <div class="btn-general mt-3" style="height:44px;" @click="enviarSolicitud">
                Enviar solicitud de afiliación
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
export default {
    props:{
        convenio: {
            type: Object,
            default:() => {}
        },
    },
    data(){
        return {
            model: {
                cedula: null,
                salario: null,
                fechaIngreso: null,
                file: null,
                fileRequired: 0,
                tipoFile: null,
            },
        }
    },
    computed: {
        validador(){
            const valid = {
                cedula: this.convenio.cedula_requerida === 2,
                salario: this.convenio.salario_requerido === 2,
                documento: this.convenio.documento_requerido === 2
            }
            return valid
        },
        tipoDocumento(){
            switch (this.convenio.documento_tipo){
            case 1:
                return ['jpg','png','jpeg']
            case 2:
                return ['pdf']
            case 3:
                return ['jpg','png','jpeg','pdf']
            default:
                return ['jpg','png','jpeg','pdf']
            }
        }
    },
    methods: {
        async enviarSolicitud(){
            const valid = await this.$refs.validacion.validate()
            if(!valid)return;
            if(this.convenio.documento_requerido == 2){
                if(!this.model.file)return;
                this.model.fileRequired = 1;
            }
            this.$emit('enviarSolicitud', this.model);
        },

        changeFile(file){
            const ext = file.name.split('.').pop();
            if(!this.tipoDocumento.includes(ext)){
                return this.notificacion('Advertencia', 'El archivo no cumple con la extensión requerida', 'warning');
            };

            this.model.tipoFile = ext == 'pdf' ? 2 : 1
            this.model.file = file.raw

            this.model.fileRequired = 1;
        },

        removeFile(){
            this.model.file = null;
            this.model.fileRequired = 0;
        },

        tipoDocumentoString(){
            switch(this.convenio.documento_tipo){
            case 1:
                return "( jpg, png, jpeg )";
            case 2:
                return "( pdf )";
            default:
                return "( jpg, png, jpeg, pdf )";
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.space-image{
    width: 240px;
    height: 426px;
    border-radius: 4px;
    position: relative;
    border: 1px solid #DBDBDB;
    .circled-cancel{
        width: 28px;
        height: 28px;
        right: 11px;
        top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: absolute;
        cursor: pointer;
        z-index: 5;
        color: #FFFFFF;
        background-color: #7F7F7F;
    }
}
.card-file{
    height: 60px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 4px #00000014;
    .space-file{
        width: 48px;
        height: 100%;
        border-radius: 12px 0px 0px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #DFE4E8;
    }
    .space-delete{
        width: 60px;
        height: 60px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        background-color: #FF5158;
    }
}
</style>